import React from "react";
import "./../App.css";
import git from "./../Assets/github.png";
import mail_icon from "./../Assets/mail_icon.png";

const Footer = () => {
    return (
        <div id="footer">
            <p
                style={{
                    color: "white",
                    fontSize: 20,
                }}
            >
                Contact details:
                <br />
            </p>

            <a
                href="mailto:joonatan.depascale@outlook.com"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    style={{
                        width: 50,
                        height: 50,
                        marginRight: 30,
                    }}
                    src={mail_icon}
                    alt="email"
                />
            </a>

            <a
                href="https://fi.linkedin.com/in/depajo/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    style={{
                        width: 50,
                        height: 50,
                        marginRight: 30,
                    }}
                    src="https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/linkedin_circle-512.png"
                    alt="linkedin"
                />
            </a>

            <a
                href="https://github.com/Depajo"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: "100px",
                    }}
                    src={git}
                    alt="github"
                />
            </a>
        </div>
    );
};

export default Footer;
