import React from "react";
import "./../App.css";
import { Dialog, Button, IconButton, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Receipt = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleClickOpen}>Show More</Button>
            <Dialog
                sx={{
                    width: "100%",
                }}
                maxWidth="xl"
                open={open}
                onClose={handleClose}
            >
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        margin: "5px",
                    }}
                >
                    <IconButton sx={{ padding: 3 }} onClick={handleClose}>
                        <CloseIcon fontSize="large" />{" "}
                    </IconButton>
                </div>

                <div
                    style={{
                        backgroundColor: "#334359",
                    }}
                >
                    <div className="content">
                        <h1
                            style={{
                                width: "100%",
                                fontSize: 40,
                                margin: 40,
                                letterSpacing: "2px",
                                textAlign: "center",
                            }}
                        >
                            Receipt API
                        </h1>
                        <p
                            style={{
                                fontSize: 20,
                                margin: 20,
                                maxWidth: "1000px",
                                letterSpacing: "1.5px",
                                lineHeight: "1.8",
                            }}
                        >
                            This is a project I made during a backend
                            development course. I used Node.js and Express to
                            create this RESTful API.
                            <br />I also created a frontend for this API. You
                            can test the API clicking the link below.
                            <br />
                            <Link
                                href="https://www.receipt.joonatandepascale.fi"
                                target="_blank"
                            >
                                Receipt API page
                            </Link>
                            <br />
                            You can find more information about this API on my
                            github page.
                        </p>
                        <br />
                        <Link
                            href="https://github.com/Depajo/Receipt-Api"
                            target={"_blank"}
                            fontSize={20}
                        >
                            Receipt API Github
                        </Link>
                        <br />
                        <br />
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default Receipt;
