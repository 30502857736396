import React from "react";
import "./../App.css";
import { Dialog, Button, IconButton, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Kierratysmania = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleClickOpen}>Show More</Button>
            <Dialog
                sx={{
                    width: "100%",
                }}
                open={open}
                maxWidth="xl"
                onClose={handleClose}
            >
                <div
                    style={{
                        width: "100%",
                        // minWidth: "450px",

                        backgroundColor: "#334359",
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            margin: "5px",
                        }}
                    >
                        <IconButton sx={{ padding: 3 }} onClick={handleClose}>
                            <CloseIcon fontSize="large" />{" "}
                        </IconButton>
                    </div>

                    <div>
                        <div>
                            <h1
                                style={{
                                    fontSize: 40,
                                    margin: 40,
                                    letterSpacing: "2px",
                                    textAlign: "center",
                                    wordBreak: "break-word",
                                }}
                            >
                                Kierrätysmania
                            </h1>

                            <div style={{ textAlign: "center" }}>
                                <iframe
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        minHeight: "250px",
                                    }}
                                    src="https://www.youtube.com/embed/UnDDfwz8_sk"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <br />
                            <br />
                            <p
                                style={{
                                    fontSize: 20,
                                    margin: 20,
                                    maxWidth: "1000px",
                                    letterSpacing: "1.5px",
                                    lineHeight: "1.8",
                                }}
                            >
                                This is a project we did at Univeristy in the
                                spring of 2022. We were a team of 4 people: two
                                programmers and two artists. I was one of the
                                programmers. You can play this game on your
                                android phone. The game is about recycling. We
                                created this game using Unity and C#. This was
                                the first time I did game programming.
                                <br />
                                <br />
                                You can reed more about the game from the game
                                project website. You can also download the game
                                from the Google Play store.
                            </p>
                            <div
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Link
                                    href="https://webpages.tuni.fi/21tiko1c/"
                                    target={"_blank"}
                                    fontSize={20}
                                >
                                    Game project website
                                </Link>

                                <br />
                                <br />
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.tiko1c.Kierrtysmania&hl=en_US&gl=US"
                                    target={"_blank"}
                                >
                                    <img
                                        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                        style={{ width: "200px" }}
                                    />
                                </a>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default Kierratysmania;
