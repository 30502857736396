import React from "react";
import "./../App.css";
import { Dialog, Button, IconButton, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import lottoappPng from "./../Assets/lottoapp.png";

const Lottoapp = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleClickOpen}>Show More</Button>
            <Dialog
                sx={{
                    width: "100%",
                }}
                open={open}
                onClose={handleClose}
                maxWidth="xl"
            >
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        margin: "5px",
                    }}
                >
                    <IconButton sx={{ padding: 3 }} onClick={handleClose}>
                        <CloseIcon fontSize="large" />{" "}
                    </IconButton>
                </div>

                <div
                    style={{
                        backgroundColor: "#334359",
                    }}
                >
                    <div className="content">
                        <h1
                            style={{
                                width: "100%",
                                fontSize: 40,
                                margin: 40,
                                letterSpacing: "2px",
                                textAlign: "center",
                            }}
                        >
                            Lotto-App
                        </h1>

                        <img
                            src={lottoappPng}
                            alt="lottoapp"
                            style={{ width: "100%", maxWidth: "400px" }}
                        />

                        <p
                            style={{
                                fontSize: 20,
                                margin: 20,
                                maxWidth: "1000px",
                                letterSpacing: "1.5px",
                                lineHeight: "1.8",
                            }}
                        >
                            This project is called Lotto app. It tells you how
                            many years it would take to win the lottery, or get
                            the seven numbers right. I created this app using
                            Java. This was the first app I created.
                            <br />
                            You can find more information about this app on my
                            github page.
                        </p>

                        <Link
                            href="https://github.com/Depajo/Lotto-App"
                            target={"_blank"}
                            fontSize={20}
                        >
                            Lotto-App Github
                        </Link>
                        <br />
                        <br />
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default Lottoapp;
