import { Paper, Link } from "@mui/material";
import React from "react";
import "./../App.css";
import Kierratysmania from "../Projects/Kierratysmania";
import Lottoapp from "../Projects/Lottoapp";
import Receipt from "../Projects/Receipt";
import Todoapp from "../Projects/Todoapp";

const Projects = () => {
    return (
        <div className="project">
            <div>
                <h1
                    style={{
                        fontSize: 50,
                        margin: 40,
                        letterSpacing: "4px",
                        textAlign: "center",
                    }}
                >
                    Portfolio
                </h1>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        maxWidth: "1200px",
                        margin: "auto",
                        justifyContent: "center",
                    }}
                >
                    <Project
                        title={"Kierrätysmania"}
                        text={
                            "This is a game what you can play on your android phone. The game is about recycling."
                        }
                        childer={<Kierratysmania />}
                    />

                    <Project
                        title={"Lotto-App"}
                        text={
                            "Lotto app tells you how meny years you need to play to win the seven numbers right."
                        }
                        childer={<Lottoapp />}
                    />

                    <Project
                        title={"Todo App"}
                        text={
                            "This is a online to-do list where you can add tasks and delete them when they are done."
                        }
                        childer={<Todoapp />}
                    />

                    <Project
                        title={"Receipt Api"}
                        text={
                            "This is an API for tracking your expenses, I madefor a school project."
                        }
                        childer={<Receipt />}
                    />
                </div>
            </div>
        </div>
    );
};

const Project = ({ title, text, childer }) => {
    return (
        <Paper
            sx={{
                margin: 2,
                padding: 2,
                backgroundColor: "#2C2C2C",
                boxShadow: "0px 10px 10px 0px #1c1c1c",
            }}
        >
            <h3 style={{ fontSize: 30, margin: 20 }}>{title}</h3>
            <p
                style={{
                    fontSize: 20,
                    margin: 20,
                    maxWidth: "400px",
                    letterSpacing: "1px",
                    lineHeight: "1.5",
                }}
            >
                {text}
            </p>
            {childer}
        </Paper>
    );
};

export default Projects;
