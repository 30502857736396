import { Button } from "@mui/material";
import React from "react";
import "./../App.css";

const About = () => {
    return (
        <div>
            <div className="content">
                <h1
                    style={{
                        fontSize: 50,
                        margin: 40,
                        letterSpacing: "4px",
                        textAlign: "center",
                    }}
                >
                    About
                </h1>
                <p
                    style={{
                        fontSize: 20,
                        margin: 20,
                        padding: 20,
                        maxWidth: "1000px",
                        letterSpacing: "1.5px",
                        lineHeight: "1.8",
                    }}
                >
                    I'm a coding student from Finland. I'm currently studying at
                    the Tampere University Of Applied Sciences. I'm interested
                    in programming and I have been studying on my third year
                    now. I would like to work as a developer in the future.
                    <br />
                    <br />
                    Programming languages that i have studied are JavaScript,
                    Java, Swift and Kotlin. I have also been studying databases
                    such as MySQL. For my projects instead I have used Github
                    version control system. I am interested in web and mobile
                    development.
                    <br />
                    <br />I am currently looking for job opportunities as a
                    developer. I would like to work in web or application
                    development. I like to learn new things and I am
                    enthusiastic about new technologies.
                </p>
            </div>
        </div>
    );
};

export default About;
