import React from "react";
import "./../App.css";

const NotFound = () => {
    return (
        <div>
            <div className="content">
                <h1>404</h1>
                <p>Page not found.</p>
            </div>
        </div>
    );
};

export default NotFound;
