import React from "react";
import "./../App.css";
import kuva from "./../Assets/kuva.jpeg";
import Projects from "../Components/Projects";
import About from "../Components/About";
const Mainpage = () => {
    return (
        <div>
            <div className="content" id="home">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#2C2C2C",
                        boxShadow: "0px 0px 20px 0px #1c1c1c",
                        width: "100%",
                        height: "100%",
                        padding: 40,
                    }}
                >
                    <img
                        style={{
                            borderRadius: "100%",
                            width: 300,
                            height: 300,
                            margin: 0,
                        }}
                        src={kuva}
                        alt="kuva"
                    />
                </div>

                <div
                    className="content"
                    style={{
                        marginBottom: 40,
                    }}
                >
                    <h1
                        style={{
                            fontSize: 50,
                            margin: 40,
                            letterSpacing: "3px",
                            textAlign: "center",
                        }}
                    >
                        Joonatan De Pascale
                    </h1>
                    <h2
                        style={{
                            fontSize: 30,
                            marginTop: 20,
                            textAlign: "center",
                        }}
                    >
                        Programmer and student
                    </h2>
                    <p
                        style={{
                            fontSize: 20,
                            margin: 20,
                            padding: 20,
                            maxWidth: "1000px",
                            letterSpacing: "1.5px",
                            lineHeight: "1.8",
                        }}
                    >
                        I am interested in frontend development and love to work
                        with JavaScript, React, React Native and Swift.
                        <br />
                        <br />
                        Currently I study programming at Tampere University Of
                        Applied Sciences. I find it fascinating to see a web
                        site or an application to take form. I'd like to take
                        part of that process and work as a developer in the
                        future.
                        <br />
                    </p>
                </div>
                <div
                    id="portfolio"
                    className="project"
                    style={{ boxShadow: "0px 0px 30px 0px #1c1c1c" }}
                >
                    <Projects />
                </div>
                <div id="about">
                    <About />
                </div>
            </div>
        </div>
    );
};

export default Mainpage;
