import "./App.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { AppBar, Button, Box } from "@mui/material";
import Mainpage from "./Screens/Mainpage";
import NotFound from "./Screens/NotFound";
import Footer from "./Components/Footer";

function App() {
    return (
        <BrowserRouter>
            <Box sx={{ marginBottom: 5, boxShadow: 3 }}>
                <AppBar
                    position="fixed"
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        bgcolor: "#5F5F5F",
                        padding: 1,
                    }}
                >
                    <Button
                        sx={{
                            flexGrow: 1,
                            maxWidth: "100px",
                            color: "#EAEAEA",
                        }}
                    >
                        <a className="link" href="#home">
                            {" "}
                            Home
                        </a>
                    </Button>
                    <Button
                        sx={{
                            flexGrow: 1,
                            maxWidth: "120px",
                            color: "#EAEAEA",
                            margin: 0,
                        }}
                    >
                        <a className="link" href="#portfolio">
                            {" "}
                            Portfolio
                        </a>
                    </Button>
                    <Button
                        sx={{
                            flexGrow: 1,
                            maxWidth: "120px",
                            color: "#EAEAEA",
                        }}
                    >
                        <a className="link" href="#about">
                            {" "}
                            About
                        </a>
                    </Button>
                </AppBar>
            </Box>
            <Routes>
                <Route path="/" element={<Mainpage />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
